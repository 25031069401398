<script>
import AdvancedTable from "../../../components/elements/advanced-table.vue";

export default {
    components: {
        AdvancedTable,
    },

    computed: {
        fields: function () {
            return [
                {
                    key: "name",
                    label: this.$t('users.columns.name').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "prefix",
                    label: this.$t('users.columns.prefix').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "firstname",
                    label: this.$t('users.columns.firstname').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "lastname",
                    label: this.$t('users.columns.lastname').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "email",
                    label: this.$t('users.columns.email').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "phone",
                    label: this.$t('users.columns.phone').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "is_active",
                    label: this.$t('users.columns.is_active').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ];
        },
    },

    data() {
        return {};
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'users.user', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <advanced-table
        :caption="$tc('users.user', 2).ucFirst()"
        :create-link="{ name: 'user-create' }"
        :current-page="1"
        :edit-link="{ name: 'user-edit' }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :per-page="10"
        borderless
        deletable
        editable
        hover
        moduleNamespace="user"
        outlined
        permission="users"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="id"
        sort-direction="asc"
        sort-icon-left
    >
    </advanced-table>
</template>
